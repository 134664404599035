//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddPriceVar from "@/components/modals/ModalAddPriceVar.vue";
import ModalPriceVarImport from "@/components/modals/ModalPriceVarImport.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onCreateList() {
      this.$access.hasOrThrow("priceVarList");
      this.$refs.modalAddPriceVar.open();
    },
    onAdded(item) {
      this.$router.push({
        name: "retail_price_monitoring",
        params: {
          id: item.id
        }
      });

      // setTimeout(() => {
      //     this.$refs.modalPriceVarImport.open({
      //         item,
      //         freshList: true,
      //         onComplete() {
      //             if (this.$route.name === "priceVarList") {
      //                 window.document.location.reload();
      //             } else {
      //                 this.$router.push({ name: "priceVarList" });
      //             }
      //         },
      //     });
      // }, 100);
    }
  },

  components: {
    ModalAddPriceVar,
    ModalPriceVarImport
  }
};