//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getRrcMonitorArray, getMarketplacesListArray, getRrcScheduleArray
//getRrcPriceTypeArray,
} from "@/utils/enums";
import Tooltip from "@/components/Tooltip";
export default {
  mixins: [ModalMixin],
  data() {
    const select_monitor_items = [...getRrcMonitorArray()];
    const select_marketplace_items = [...getMarketplacesListArray()];
    const select_shedule_items = [...getRrcScheduleArray()];
    //let select_entity_items = [...getRrcPriceTypeArray('wb')];

    // let select_entity_items = [];
    // let select_monitor_items = [];

    /* // Разделяем на 2 массива
    select_type_items.forEach((item) => {
        [
            "product",
            //, "brand", "seller", "category"
        ].forEach((el) => {
            if (item.id.indexOf(el) === 0) {
                select_entity_items.push({
                    id: el,
                    title: item.title.split(":")[0],
                });
                //} else {
                select_monitor_items.push({
                    id: item.id,
                    title: item.title.split(":")[1],
                });
            }
        });
    });
    */

    //select_entity_items = this.removeDuplicates(select_entity_items);
    //select_monitor_items = this.removeDuplicates(select_monitor_items);

    const initial_new_list = {
      id: "",
      title: "",
      //entity: select_entity_items[0],
      monitor: select_monitor_items[0],
      description: "",
      variation: "",
      shedule: select_shedule_items[0]
    };
    return {
      initial_new_list,
      new_list: {
        ...initial_new_list
      },
      entity: null,
      mp: null,
      //select_type_items,
      //select_entity_items,
      select_monitor_items,
      select_marketplace_items,
      select_shedule_items,
      loading: false,
      onComplete: null,
      variationError: false,
      variationTouched: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      item,
      onComplete
    }) {
      //console.log("1: item=", item);

      //item.shedule = this.select_shedule_items.find(el=> el.id===item.shedule)
      //item.monitor = this.select_monitor_items.find(el=> el.id===item.monitor)

      //console.log("2: item=", item);

      //console.log("1: this.new_list=",this.new_list)
      //console.log("1: this.select_monitor_items=",this.select_monitor_items)

      this.new_list = {
        ...item
      };
      //console.log("2: this.new_list=", this.new_list);

      this.new_list.shedule = this.select_shedule_items.find(el => el.id === item.shedule);
      this.new_list.monitor = this.select_monitor_items.find(el => el.id === item.monitor);

      //console.log("215: this.new_list.variation=", this.new_list.variation);

      if (this.new_list.variation == '—') {
        this.new_list.variation = "";
      }
      if (this.new_list.variation.slice(-1) == '%') {
        this.new_list.variation = this.new_list.variation.slice(0, -1);
      }

      //console.log("3: this.new_list=", this.new_list);

      this.onComplete = onComplete;
    },
    /*
    removeDuplicates(array) {
        // Declare a new array
        let newArray = [];
         // Declare an empty object
        let uniqueObject = {};
         // Loop for the array elements
        for (let i in array) {
            // Extract the title
            let objTitle = array[i]["id"];
             // Use the title as the index
            uniqueObject[objTitle] = array[i];
        }
         // Loop to push unique object into array
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }
        return newArray;
    },
    */
    async submit({
      close
    }) {
      //console.log('234: submit')

      this.loading = true;
      try {
        //const monitor_id = this.new_list.monitor.id;
        //const entity_id = this.new_list.entity;     //?.id;

        //console.log("241: ->updatePriceVar");
        //console.log("242: this.new_list=", this.new_list);

        const result = await this.$store.dispatch("priceVar/updatePriceVar", {
          id: this.new_list.id,
          title: this.new_list.title,
          description: this.new_list.description,
          //entity: entity_id,
          monitor: this.new_list.monitor.id,
          variation: this.new_list.variation,
          shedule: this.new_list.shedule.id
        });
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        //console.log('260: e=', e)

        this.loading = false;
      }
    },
    isValidNumberString(str) {
      // Check if the string is not empty
      if (str.length < 2) return false;

      // Check if the first character is either '+' or '-'
      if (str[0] !== "+" && str[0] !== "-") return false;

      // Check if the rest of the characters are all numbers
      for (let i = 1; i < str.length; i++) {
        if (!/\d/.test(str[i])) {
          return false;
        }
      }

      // If all conditions pass, return true
      return true;
    }
  },
  computed: {
    current_select_monitor_items() {
      return this.select_monitor_items.filter(item => {
        var _this$new_list$entity;
        return item.id.startsWith((_this$new_list$entity = this.new_list.entity) === null || _this$new_list$entity === void 0 ? void 0 : _this$new_list$entity.id);
      });
    },
    showVariation() {
      return this.new_list.monitor.id === "productPriceVarPercent" || this.new_list.monitor.id === "productPriceVarRub";
    },
    variationValid() {
      if (this.showVariation) {
        return this.isValidNumberString(this.new_list.variation);
      } else {
        return true;
      }
    },
    valid() {
      var _this$new_list$title;
      return ((_this$new_list$title = this.new_list.title) === null || _this$new_list$title === void 0 ? void 0 : _this$new_list$title.length) && this.variationValid; //&& this.new_list.entity?.id;
    },

    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сохранить изменения",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  watch: {
    /* // Выставляем значение по умолчанию при переключении первого селекта
    current_select_monitor_items(value) {
        //console.log('5: value=', value)
        this.new_list.monitor = value[0];
    },
    */
  },
  components: {
    Modal,
    AppSelect,
    Tooltip
  }
};